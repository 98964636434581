import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import { Link } from 'gatsby'
import termsImg from '../../assets/images/terms-of-service.jpg'

const TermsOfService = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Terms of Service" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Terms of Service" 
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <img src={termsImg} alt="tt" />
                                <p><i>These terms of service were last updated on May 2024.</i></p>
                                <h3>1. Introduction</h3>
                                <blockquote className="blockquote">
                                    <p>Neolytics.io Holding Limited and its affiliates (listed in the “Affiliates” section in our Privacy Policy (collectively “Neolytics.io” “us” and “we”) and third parties we partner with use cookies and similar technologies on Neolytics.io websites (including mobile sites) and social media sites (“Sites”), mobile applications (Apps) (collectively “Online Services”) to give users (“you” or “your”) the best possible browsing experience.</p>
                                </blockquote>
                                <h3></h3>
                                <p>Personal data is any information that identifies you as an individual or relates to you as an identifiable individual.</p>
                                <p>We use tools like cookies, web beacons, analytics services, and advertising providers to gather the data listed above. Some of these tools offer you the ability to opt out of data collection.</p>
                                <p>Depending on how you interact with us, personal data we collect may include:</p>
                                <ol>
                                    <li>your name;</li>
                                    <li>email address;</li>
                                    <li>postal address;</li>
                                    <li>telephone number;</li>
                                    <li>log-in and account information for authentication purposes and account access to Neolytics.io™ platforms;</li>
                                    <li>social media account information.</li>
                                </ol>
                               
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                        <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/legal/terms-of-use">
                                                Terms of Use
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/legal/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/legal/cookie-policy">
                                                Cookie Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/legal/other-policies">
                                                Other Policies
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                    <Link to="/about-us">What is Neolytics? <span className="tag-link-count"> (3)</span></Link>
                                    <Link to="/contact">how do I contact Neolytics? <span className="tag-link-count"> (3)</span></Link>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfService;